.exit-chat {
  text-align: right;
  padding-right: 2vw;
  font-size: 32px;
}

.chat-container {
  // width: 25vw;
  // height: 80vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  // transition: display 2s ;
  .chat-wrap {
    width: 100vw;
    max-width: 100vw;
    height: 85vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 20px 0;
    h2 {
      height: 45px;
      line-height: 45px;
    }
    .chat-message-wrap {
      flex-grow: 1;
      overflow-y: auto;
      // border-bottom: 1px solid #eee;
      .ChatPeopleMessage {
        letter-spacing: 0.025em;
        font-family: Roboto, Arial, sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        background: #f1f3f4;
        border-radius: 8px;
        margin: 0.75rem;
        padding: 0.75rem;
        text-align: center;
        -webkit-user-select: none;
      }
    }
    .chat-message-box {
      // min-height: 46px;
      height: auto;
      max-height: 61px;
      box-sizing: border-box;
      padding: 0 5px;
      background-color: #f1f3f4;
      border-radius: 50px;
      > textarea {
        padding: 5px 20px;
        height: 100%;
        width: 100%;
        resize: none;
        border-radius: 50px;
        background-color: #f1f3f4;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .chat-mobile-wrap {
    position: absolute !important;
    width: 100vw !important;
    height: 100vh !important;
    left: 0;
    top: 0;
    z-index: 10;
  }

  textarea::-webkit-scrollbar {
    display: none;
  }
  .chat-disabled {
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
}
.css-1pxa9xg-MuiAlert-message {
  padding: 0 !important;
}
