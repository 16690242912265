.chat-message-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 5px 20px;
  &.myself {
    flex-direction: row-reverse;
    .chat-message-avatar {
      margin-right: 0;
      margin-left: 16px;
    }
  }
  .chat-message-avatar {
    width: 50px;
    height: 50px;
    color: #333;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    .chat-message-avatar-img {
      width: 26px;
      height: 26px;
      border-radius: 6px;
    }
  }
  .chat-message-content {
    flex-grow: 1;
    .chat-message-info {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      &.myself {
        // flex-direction: row-reverse;
        .chat-message-receiver {
          text-align: left;
        }
      }
      .chat-message-receiver {
        // flex-grow: 1;
        // text-align: left;
        > span {
          margin: 0 5px;
          color: #ccc;
        }
        > a {
          text-decoration: none;
          color: #2da5ff;
        }
      }
      .chat-message-time {
        max-width: 100px;
        color: #ccc;
      }
    }
    .chat-message-text-list {
      word-break: break-all;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .chat-message-text {
        white-space: break-spaces;
        word-break: break-word;
        // padding: 8px 10px;
        font-family: system-ui;
        border-radius: 8px;
        background: transparent;
        color: #333;
        font-size: 13px;
        margin-bottom: 0px;
        padding-top: 0.625rem;
        &:first-child {
          padding-top: 0 !important;
        }
      }
      &.myself {
        // align-items: flex-end;
        .chat-message-text {
          white-space: break-spaces;
          word-break: break-word;
          // background: #deedfd;
        }
      }
    }
  }
}
