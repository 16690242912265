.avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.avatar-active {
    // outline: 3px solid #d4e280;
    outline: 4px solid #669df6;
  }
  .video-option {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 10px;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
  }
  .corner-name {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    padding: 15px 3px 3px 5px;
    align-items: center;
    max-width: 100%;
    height: 30px;
    font-size: 14px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    //new Css
    opacity: 0.5;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-bottom: 10px;
    span {
      padding: 20px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: sans-serif;
    }
    // .anticon-audio-muted {
    //   // margin-right: 5px;
    //   color: white;
    //   position: absolute;
    //   top: 10px;
    //   right: 20px;
    //   background-color: #34373a;
    //   padding: 12px;
    //   border-radius: 30px;
    //   font-size: 16px;
    // }
  }
  .center-name {
    // width: 14%;
    font-size: 80px;
    font-weight: 700;
    color: #fff;
    // background-color: skyblue;
    padding: 8px 40px;
    border-radius: 100px;
    font-family: sans-serif;
  }
}
