.screen-share-button {
  font-size: 30px;
  color: #fff;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
  &.started-share {
    color: #00cc64;
  }
  > * {
    font-size: 28px !important;
  }
}
.screen-share-dropdown-button {
  border-radius: 20px;
  border: 1px solid #fff;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  > .ant-btn {
    font-size: 30px;
    color: #fff;
    display: flex;
    height: 60px !important;
    align-items: center;
    border-width: 0 !important;
    padding: 0;
    margin-right: 5px;
    &:hover {
      color: #40a9ff;
    }
  }
  > .ant-btn[disabled] {
    background: transparent;
    color: #999;
  }
  > .ant-dropdown-trigger {
    width: 20px !important;
    margin-right: 0;
  }
}
